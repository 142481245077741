* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;

  position: absolute;
  height: 100%;
  width: 100%;
}

svg.gradient {
  float: left;
}

.countdown-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  font-family: 'Barlow Semi Condensed', sans-serif;
  padding: 1rem 1rem 4rem;
  letter-spacing: 3px;
  position: relative;
}

.countdown-wrapper .logo-wrapper {
  display: contents;
  position: relative;
  margin-bottom: 30px;
  -webkit-transition: margin 0.5s;
  transition: margin 0.5s;
}

.countdown-wrapper .logo-wrapper .glow {
  position: absolute;
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.countdown-wrapper .pre-text {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}

.countdown-wrapper .pre-text a {
  display: table;
  margin: 1rem auto;
  font-size: 1.4rem;
}

.countdown-wrapper .pre-text a img {
  height: 70px;
}

.countdown-wrapper .pre-text--small {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.countdown-wrapper .pre-text sup {
  font-size: 70%;
  vertical-align: super;
}

.countdown-wrapper .countdown,
.countdown-wrapper .badges-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px;
}

.countdown-wrapper .countdown:last-of-type {
  margin-bottom: 0;
}

.countdown .item,
.countdown-wrapper .launch-text {
  position: relative;
  color: #fff;
  font-size: 0;
}

.countdown .item .text {
  text-align: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.countdown .item .text .digits {
  font-size: 2.8rem;
}

.countdown--small .item .text .digits {
  font-size: 2rem;
}

.countdown .item .text .digits + span {
  font-size: 1rem;
  display: block;
  text-transform: uppercase;
}

.countdown--small .item .text .digits + span {
  font-size: 0.6rem;
}

.countdown svg.circle {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  overflow: visible;
}

.countdown svg.circle circle:first-of-type {
  opacity: 0.25;
  -webkit-transition: all 1s linear;
  transition: all 1s linear;
}

.countdown .circle_animation {
  stroke-dasharray: 630;
  /* this value is the pixel circumference of the circle */
  stroke-dashoffset: 0;
  -webkit-transition: all 1s linear;
  transition: all 1s linear;
  stroke-linecap: round;
}

.countdown--small .circle_animation {
  stroke-dasharray: 408;
}

.countdown-wrapper .badge {
  pointer-events: visible;
  height: 54px;
  margin: 4px 10px 14px;
}

.countdown-wrapper .badge.badge-web {
  margin-top: 4rem;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 10px 0 6px;
  font-size: 18px;
  letter-spacing: 1px;
}

.countdown-wrapper .badge.badge-web img {
  height: 100%;
  margin-right: 3px;
}

@media screen and (max-width: 991px) {
  .countdown .item .text .digits {
    font-size: 2.4rem;
  }

  .countdown-wrapper {
    letter-spacing: 1px;
  }

  .countdown-wrapper .logo-wrapper {
    margin-bottom: 10px;
  }

  .countdown-wrapper .pre-text {
    margin-bottom: 1rem;
    /*padding-bottom: 1rem;*/
  }

  .countdown svg.circle {
    width: 150px;
    height: 150px;
  }

  .countdown .circle_animation {
    stroke-dasharray: 408;
  }

  .countdown circle {
    r: 65px;
    cx: 75px;
    cy: 75px;
  }
}

.input-group > input[type='email'] {
  margin-bottom: calc(14px + 1rem);
}

.input-group + span:last-of-type {
  margin-top: 1rem;
}

/* =============================================================================
   HTML5 CSS Reset Minified - Eric Meyer
   ========================================================================== */

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

li {
  list-style: none;
}

/* =============================================================================
   My CSS
   ========================================================================== */

/* ---- base ---- */

html,
body {
  margin: 0;
  background: black;
  position: absolute;
  height: 100%;
  width: 100%;

  animation-name: background-border-animation;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}

@media (min-width: 767px) {
  @keyframes background-border-animation {
    0% {
      background: rgba(190, 5, 158, 1);
      border: 200px solid rgba(190, 5, 158, 0.1);
    }
    100% {
      background: black;
      border: 0px solid rgba(190, 5, 158, 0.1);
    }
  }
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font: normal 75% Arial, Helvetica, sans-serif;
}

canvas {
  display: block;
  vertical-align: bottom;
}

/* ---- particles.js container ---- */

#particles-js {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: fixed;
  z-index: 0;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aeaeaf;
  opacity: 1;
  /* Firefox */
}
